<template>
  <div class="w-100 px-2 mt-5">
    <v-container fluid class="pt-0 pb-15 d-flex flex-column">
      <span class="white--text font-30 app-medium-font ml-5 mb-3">{{
        $t("reported")
      }}</span>
      <v-data-table
        :headers="headers"
        :items="events"
        :items-per-page="15"
        :loading="loading"
        class="elevation-0"
      >
        <template v-slot:[`item.event`]="{ item }">
          <div style="max-width: 610px;">
            <feed-event-card
              :_id="item._id"
              v-if="item._id"
              :ref="`event${item._id}`"
              :onDeleted="onDeleted"
            />
          </div>
        </template>
        <template v-slot:[`item.text`]="{ item }">
          <div style="max-width: 580px; " class="text-block">
            {{ item.text }}
          </div>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ formatDate(item.createdAt) }}
        </template>
        <template v-slot:[`item.reporter_avatar`]="{ item }">
          <div @click="goToProfile(item)"></div>
        </template>
        <template v-slot:[`item.reported_count`]="{ item }">
          {{ item.reported.length }}
        </template>
        <template v-slot:[`item.reported`]="{ item }">
          <div>
            <div v-for="report in item.reported" :key="report._id">
              {{ report.reason }}
              reported by:
              <editable-avatar
                :image="
                  report.poster_thumbnail
                    ? report.poster_thumbnail
                    : report.poster_image
                "
                :editable="false"
                :size="30"
                class="my-3"
              />
              {{
                report.poster_name
                  ? report.poster_name
                  : report.first_name + " " + report.last_name
              }}
            </div>
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  style="border-radius: 1px; border-color: #0A5B8A; border-style: solid; border-radius: 36px;"
                  small
                  class="ml-2"
                  @click.stop="onEditEvent(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="#0A5B8A" style="margin-top: 2px;" size="18"
                    >mdi-pencil</v-icon
                  >
                </v-btn>
              </template>
              Edit
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  style="border-radius: 1px; border-color: #0A5B8A; border-style: solid; border-radius: 36px;"
                  small
                  class="ml-2"
                  @click.stop="onDeleteEvent(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="#0A5B8A" style="margin-top: 2px;" size="18"
                    >mdi-delete</v-icon
                  >
                </v-btn>
              </template>
              Delete
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  style="border-radius: 1px; border-color: #0A5B8A; border-style: solid; border-radius: 36px;"
                  small
                  class="ml-2"
                  @click.stop="onResolveEvent(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="#0A5B8A" style="margin-top: 2px;" size="18"
                    >mdi-check</v-icon
                  >
                </v-btn>
              </template>
              Mark as resolved
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
      <v-dialog v-model="resolveDialog" persistent max-width="600px">
        <v-card>
          <v-card-text
            class="pb-0 pt-8"
            style="align-items: center; display: flex; flex-direction: column;"
          >
            <v-icon
              size="60"
              color="green"
              class="mb-5"
              v-text="'mdi-alert-circle-outline'"
            />
            <div style="font-size: 20px">
              Are you sure to make this report as resolved? It will ignore all
              reports about this event.
            </div>
          </v-card-text>
          <v-card-actions
            style="display: flex; flex-direction: row; justify-content:center;"
            class="pb-6 mt-3"
          >
            <v-btn
              color="blue white"
              @click="onClose"
              class="white--text"
              v-text="'Cancel'"
            />
            <v-btn
              color="red white"
              @click="onConfirm"
              class="white--text"
              :loading="loading"
            >
              Yes, resolve it!
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import dateFormat from "dateformat";
import EditableAvatar from "@/components/EditableAvatar.vue";
import FeedEventCard from "../../event_feeds/FeedEventCard.vue";

export default {
  components: { EditableAvatar, FeedEventCard },
  data() {
    return {
      loading: false,
      events: [],
      headers: [
        { text: "", value: "event" },
        { text: "Reported Count", value: "reported_count" },
        { text: "Info", value: "reported" },
        { text: "Actions", value: "actions" },
      ],
      resolveDialog: false,
      selected: null,
    };
  },
  methods: {
    ...mapActions("event", {
      getReportedEvents: "getReportedEvents",
      resolveReportedEvent: "resolveReportedEvent",
    }),
    onSelectEvent(event) {
      console.log(event);
    },
    formatDate(date) {
      return dateFormat(this.convertTZ(date, this.timezone), "mm/dd hh:MM TT");
    },
    convertTZ(date, tzString) {
      if (!date) return "";
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: tzString ? tzString : "America/New_York",
          }
        )
      );
    },
    onEditEvent(event) {
      this.$refs[`event${event._id}`].showEditDialog();
    },
    onDeleteEvent(event) {
      this.$refs[`event${event._id}`].showDeleteDialog();
    },
    onDeleted(event) {
      this.events = this.events.filter((e) => e._id != event._id);
    },
    onResolveEvent(event) {
      this.selected = event;
      this.resolveDialog = true;
    },
    onConfirm() {
      this.resolveReportedEvent({ _id: this.selected._id })
        .then((res) => {
          console.log(res);
          this.events = this.events.filter((event) => event._id != res._id);
          this.resolveDialog = false;
        })
        .catch((error) => {
          console.log(error.response.data.message);
          this.resolveDialog = false;
        });
    },
    onClose() {
      this.resolveDialog = false;
    },
  },
  computed: {
    ...mapGetters({
      timezone: "usersetting/getTimeZone",
    }),
  },
  mounted() {
    this.loading = true;
    this.getReportedEvents()
      .then((res) => {
        this.events = res;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error.response.data.message);
        this.loading = false;
      });
  },
};
</script>
